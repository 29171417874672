import React, { memo, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import PageHeader from '../../../components/PageHeader'
import Button from '../../../components/button'
import Loader from '../../../components/Loader'
import { ReactComponent as PlusIcon } from '../../../images/plus.svg'
import { Container, Row } from 'reactstrap'
import './index.scss'
import SessionGroup from '../../../components/sessions/group'
import SessionModal from '../../../components/sessions/modals/session'
import NewGroupModal from '../../../components/sessions/modals/group-new'
import EditGroupModal from '../../../components/sessions/modals/group-edit'
import { getItemKey } from '../../../api/db'
import { deleteFile } from '../../../api/storage'
import {
  sessionUpdate,
  sessionGroupCreate,
  sessionGroupDelete,
  sessionGroupUpdate,
  sessionCreate,
  sessionsUpdate,
  sessionGroupsSubscribeAll
} from '../../../api/sessions'

const AddGroupButtonText = () => (
  <div className="d-flex align-items-center gap-3">
    <PlusIcon style={{
      width: '11px',
      height: '12px'
    }}
    />
    Add Group
  </div>
)

const Sessions = memo(() => {
  const [groups, setGroups] = useState()
  const [loading, setLoading] = useState(true)
  const [isAddSessionModalOpen, setIsAddSessionModalOpen] = useState(false)
  const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditGroupModalOpen] = useState(false)
  const [currentGroupId, setcurrentGroupId] = useState(null)
  const [currentSessionId, setcurrentSessionId] = useState(null)

  useEffect(() => {
    const unsubscribe = sessionGroupsSubscribeAll((sessions) => {
      const sessionsOrdered = _.sortBy(sessions, session => session.metadata.rank)

      setGroups(sessionsOrdered)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const openNewGroupModal = () => {
    setIsNewGroupModalOpen(true)
  }

  const deleteGroup = (groupId) => {
    const files = []
    const group = _.find(groups, { id: groupId })

    _.each(group.metadata.sessions, session => {
      if (session.image) {
        files.push(session.image)
      }

      if (session.file) {
        files.push(session.file)
      }
    })

    files.forEach(file => {
      deleteFile(file)
    })

    setcurrentGroupId(null)
    sessionGroupDelete(groupId)
  }

  const editGroup = (groupId) => {
    setcurrentGroupId(groupId)
    setIsEditGroupModalOpen(true)
  }

  const openSessionModal = (groupId, sessionId = null) => {
    if (!sessionId) {
      sessionId = uuid()
    }

    setcurrentGroupId(groupId)
    setcurrentSessionId(sessionId)
    setIsAddSessionModalOpen(true)
  }

  const resetSessionModal = () => {
    setIsAddSessionModalOpen(false)
    setcurrentSessionId(null)
    setcurrentGroupId(null)
  }

  const resetAddNewGroupModal = () => {
    setIsNewGroupModalOpen(false)
  }

  const resetEditGroupModal = () => {
    setIsEditGroupModalOpen(false)
    setcurrentGroupId(null)
  }

  const sessionsUpdated = (groupId, sessions) => {
    const group = _.find(groups, { id: groupId })
    sessionsUpdate(group, sessions)
  }

  const onSessionSubmit = (groupId, sessionData) => {
    const group = _.find(groups, { id: groupId })
    const sessions = group.metadata.sessions
    if (_.findIndex(sessions, { id: currentSessionId }) !== -1) {
      sessionUpdate(group, currentSessionId, sessionData)
    } else {
      sessionData.rank = _.size(sessions) + 1
      sessionCreate(group, sessionData)
    }
    resetSessionModal()
  }

  const onAddNewGroupSubmit = async (values) => {
    const group = {
      name: values.title,
      rank: groups.length > 0 ? _.last(groups).metadata.rank + 1 : 1,
      sessions: []
    }
    sessionGroupCreate(group)
    setIsNewGroupModalOpen(false)
  }

  const onEditGroupSubmit = async (values) => {
    sessionGroupUpdate(currentGroupId, { name: values.title })
    resetEditGroupModal()
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const newGroups = Array.from(groups)
    const [reorderedItem] = newGroups.splice(result.source.index, 1)
    newGroups.splice(result.destination.index, 0, reorderedItem)

    const groupsChanged = []
    newGroups.map((group, index) => {
      if (group.metadata.rank !== index + 1) {
        groupsChanged.push({
          sessionId: group.id,
          rank: index + 1
        })
      }
      return true
    })

    setGroups(newGroups)

    groupsChanged.forEach(({ sessionId, rank }) => {
      sessionGroupUpdate(sessionId, { rank })
    })
  }

  return (
    <Container className="sessions">
      <PageHeader title="Sessions">
        <Button onClick={() => openNewGroupModal()} text={<AddGroupButtonText />} />
      </PageHeader>
      <div className="sessions__content">
        {!loading &&
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="group__header">
              {(provided) => (
                <ul className="sessions__list" {...provided.droppableProps} ref={provided.innerRef}>
                  {groups?.map((group, index) => (
                    <Draggable key={getItemKey(group)} draggableId={`session-group-${group.id}`} index={index}>
                      {(prov) => (
                        <SessionGroup
                          group={group}
                          forwardRef={prov.innerRef}
                          draggableProps={prov.draggableProps}
                          dragHandleProps={prov.dragHandleProps}
                          title={group.metadata.name}
                          items={group.metadata.sessions}
                          groupId={group.id}
                          addSessionHandle={openSessionModal}
                          editGroupHandle={editGroup}
                          deleteGroupHandle={deleteGroup}
                          editSessionHandle={openSessionModal}
                          sessionsUpdated={sessionsUpdated}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        }
        {loading &&
          <Row className="center">
            <Loader></Loader>
          </Row>
        }
      </div>
      {isAddSessionModalOpen &&
        <SessionModal
          isOpen={isAddSessionModalOpen}
          onSubmit={onSessionSubmit}
          toggle={resetSessionModal}
          sessionId={currentSessionId}
          groupId={currentGroupId}
          groups={groups}
          uploadPath={'sessions'}
        />
      }
      {isNewGroupModalOpen &&
        <NewGroupModal
          title="Add a group"
          isOpen={isNewGroupModalOpen}
          toggle={resetAddNewGroupModal}
          onSubmit={onAddNewGroupSubmit}
        />
      }
      {isEditModalOpen &&
        <EditGroupModal
          title="Edit group"
          group={_.find(groups, { id: currentGroupId })}
          isOpen={isEditModalOpen}
          toggle={resetEditGroupModal}
          onSubmit={onEditGroupSubmit}
        />
      }
    </Container>
  )
})

Sessions.displayName = 'Sessions'

export default Sessions
