/* eslint-disable */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as PencilIcon } from '../../images/pencil-fill.svg';
import { ReactComponent as TrashIcon } from '../../images/trash.circle.fill.svg';
import { getItemKey } from '../../api/db';
import { deleteFile } from '../../api/storage'
import Session from './session';
import SessionDeleteModal from './modals/session-delete-confirmation'

const sortCards = (items) => {
  return _.sortBy(_.values(items), (item) => parseInt(item.rank, 10))
}

const Group = ({
  title,
  items = [],
  forwardRef,
  draggableProps,
  dragHandleProps,
  addSessionHandle,
  editSessionHandle,
  editGroupHandle,
  deleteGroupHandle,
  groupId,
  groupPropsEditDisabled,
  sessionsUpdated,
}) => {
  const [cards, setCards] = useState([]);
  const [deleteSessionId, setDeleteSessionId] = useState(false)
 
  useEffect(() => {
    if(items.length > 0) {
      setCards(sortCards(items))
    }
  }, [items]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) { return; }
    const newCardsArray = Array.from(cards);
    const [reorderedItem] = newCardsArray.splice(result.source.index, 1);
    newCardsArray.splice(result.destination.index, 0, reorderedItem);
    const newCards = [];
    newCardsArray.forEach((card, index) => {
      const id = card.id ? card.id : uuid();
      const rank = index + 1;
      newCards.push({
        ...card,
        id,
        rank,
      });
    });
 
    setCards(newCards);
    sessionsUpdated(groupId, newCards);
  };

  const editSession = (sessionId) => {
    editSessionHandle(
      groupId,
      sessionId,
    );
  };

  const deleteSessionFiles = (session) => {
    const files = []

    if(session.image) {
      files.push(session.image)
    }

    if(session.file) {
      files.push(session.file)
    }

    files.forEach(file => {
      deleteFile(file)
    })
  }

  const deleteSession = (sessionId) => {
    const session = _.find(cards, { id: sessionId });
    deleteSessionFiles(session);
    const newCards = _.reject(cards, {id: sessionId});
    setCards(newCards);
    setDeleteSessionId(null)
    sessionsUpdated(groupId, newCards);
  };

  const deleteSessionStart = (sessionId) => {
    setDeleteSessionId(sessionId)
  }

  return (
    <div className="group" ref={forwardRef} {...draggableProps} data-id={groupId}>
      <div className="group__header">
        <button type="button" onClick={() => addSessionHandle(groupId)} className="group__btn">
          <div className="group__icon group__icon_type_plus">
            <PlusIcon />
          </div>
        </button>
        <h3 className="group__title handle" {...dragHandleProps} style={{ cursor: 'grabbing' }}>{title}</h3>
        {!groupPropsEditDisabled &&         
          <button type="button" onClick={() => editGroupHandle(groupId)} className="group__btn" title="Edit group">
            <div className="group__icon group__icon_type_pencil">
              <PencilIcon />
            </div>
          </button>
        }
        {!groupPropsEditDisabled && items.length === 0 &&
          <button type="button" onClick={() => deleteGroupHandle(groupId)} className="group__btn" title="Delete group">
            <div className="group__icon group__icon_type_pencil">
              <TrashIcon />
            </div>
          </button>
        }
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="group__list" direction="horizontal">
          {(provided) => (
            <ul className="group__list" {...provided.droppableProps} ref={provided.innerRef}>
              {cards.map((session, index) => (
                <Draggable key={getItemKey(session)} draggableId={`session-${session.id}`} index={index}>
                  {(prov) => (
                    <Session
                      session={session}
                      forwardRef={prov.innerRef}
                      draggableProps={prov.draggableProps}
                      dragHandleProps={prov.dragHandleProps}
                      editSession={editSession}
                      deleteSession={deleteSessionStart}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      {deleteSessionId &&
        <SessionDeleteModal
          session={_.find(items, {id: deleteSessionId})}
          isOpen={deleteSessionId !== false}
          toggle={() => setDeleteSessionId(false)}
          onSubmit={deleteSession}
        />
      }
    </div>
  );
};

export default Group;
